/*
    Icon Font orion-font generated from orioniconlibrary.com
  */

  @font-face {
  font-family: "orion-font";
  src: url("./orion-font.eot");
  src: url("./orion-font.eot?#iefix") format("embedded-opentype"),
       url("./orion-font.woff2") format("woff2"),
       url("./orion-font.woff") format("woff"),
       url("./orion-font.ttf") format("truetype"),
       url("./orion-font.svg#orion-font") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "orion-font";
    src: url("./orion-font.svg#orion-font") format("svg");
  }
}

  [data-icon]::before { content: attr(data-icon); }

  [data-icon]::before,
  .o-add-1::before,
.o-alert-1::before,
.o-angle-down-1::before,
.o-angle-down-circle-1::before,
.o-angle-left-1::before,
.o-angle-left-circle-1::before,
.o-angle-right-1::before,
.o-angle-right-circle-1::before,
.o-angle-up-1::before,
.o-angle-up-circle-1::before,
.o-arrow-down-1::before,
.o-arrow-left-1::before,
.o-arrow-left-down-1::before,
.o-arrow-left-up-1::before,
.o-arrow-right-1::before,
.o-arrow-right-down-1::before,
.o-arrow-right-up-1::before,
.o-arrow-up-1::before,
.o-bar-chart-1::before,
.o-bin-1::before,
.o-browser-window-1::before,
.o-business-report-1::before,
.o-calls-1::before,
.o-camera-1::before,
.o-checked-circle-1::before,
.o-checkmark-1::before,
.o-close-1::before,
.o-cloud-1::before,
.o-compass-direction-1::before,
.o-credit-card-1::before,
.o-display-1::before,
.o-document-1::before,
.o-download-1::before,
.o-download-cloud-1::before,
.o-edit-1::before,
.o-edit-2::before,
.o-error-bug-1::before,
.o-exclamation-mark-1::before,
.o-exit-1::before,
.o-expand-1::before,
.o-export-1::before,
.o-external-link-1::before,
.o-files-1::before,
.o-filter-data-1::before,
.o-flag-1::before,
.o-hidden-1::before,
.o-id-card-1::before,
.o-import-1::before,
.o-important-warning-1::before,
.o-info-1::before,
.o-info-help-1::before,
.o-key-1::before,
.o-lock-1::before,
.o-lock-opened-1::before,
.o-mail-1::before,
.o-map-1::before,
.o-maximize-1::before,
.o-menu-1::before,
.o-minus-1::before,
.o-moon-waning-1::before,
.o-more-1::before,
.o-new-message-1::before,
.o-numbers-1::before,
.o-pie-chart-1::before,
.o-pin-1::before,
.o-play-movie-1::before,
.o-priority-warning-1::before,
.o-question-1::before,
.o-question-help-1::before,
.o-reception-1::before,
.o-reload-1::before,
.o-repeat-1::before,
.o-rfid-signal-1::before,
.o-rotate-right-1::before,
.o-sales-down-1::before,
.o-sales-performance-down-1::before,
.o-sales-performance-up-1::before,
.o-sales-up-1::before,
.o-sand-clock-1::before,
.o-search-1::before,
.o-shield-1::before,
.o-sliders-1::before,
.o-smartphone-1::before,
.o-sorting-1::before,
.o-speaker-call-1::before,
.o-statistic-1::before,
.o-statistics-1::before,
.o-suitcase-1::before,
.o-sunny-1::before,
.o-switch-camera-1::before,
.o-team-1::before,
.o-time-1::before,
.o-type-1::before,
.o-upload-1::before,
.o-upload-to-cloud-1::before,
.o-user-1::before,
.o-view-1::before,
.o-web-link-1::before,
.o-zoom-in-1::before,
.o-zoom-out-1::before {
    display: inline-block;
  font-family: "orion-font";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  }

  .o-add-1::before { content: "\fa88"; }
.o-alert-1::before { content: "\fa89"; }
.o-angle-down-1::before { content: "\fa8a"; }
.o-angle-down-circle-1::before { content: "\fa8b"; }
.o-angle-left-1::before { content: "\fa8c"; }
.o-angle-left-circle-1::before { content: "\fa8d"; }
.o-angle-right-1::before { content: "\fa8e"; }
.o-angle-right-circle-1::before { content: "\fa8f"; }
.o-angle-up-1::before { content: "\fa90"; }
.o-angle-up-circle-1::before { content: "\fa91"; }
.o-arrow-down-1::before { content: "\fa92"; }
.o-arrow-left-1::before { content: "\fa93"; }
.o-arrow-left-down-1::before { content: "\fa94"; }
.o-arrow-left-up-1::before { content: "\fa95"; }
.o-arrow-right-1::before { content: "\fa96"; }
.o-arrow-right-down-1::before { content: "\fa97"; }
.o-arrow-right-up-1::before { content: "\fa98"; }
.o-arrow-up-1::before { content: "\fa99"; }
.o-bar-chart-1::before { content: "\fa9a"; }
.o-bin-1::before { content: "\fa9b"; }
.o-browser-window-1::before { content: "\fa9c"; }
.o-business-report-1::before { content: "\fa9d"; }
.o-calls-1::before { content: "\fa9e"; }
.o-camera-1::before { content: "\fa9f"; }
.o-checked-circle-1::before { content: "\faa0"; }
.o-checkmark-1::before { content: "\faa1"; }
.o-close-1::before { content: "\faa2"; }
.o-cloud-1::before { content: "\faa3"; }
.o-compass-direction-1::before { content: "\faa4"; }
.o-credit-card-1::before { content: "\faa5"; }
.o-display-1::before { content: "\faa6"; }
.o-document-1::before { content: "\faa7"; }
.o-download-1::before { content: "\faa8"; }
.o-download-cloud-1::before { content: "\faa9"; }
.o-edit-1::before { content: "\faaa"; }
.o-edit-2::before { content: "\faab"; }
.o-error-bug-1::before { content: "\faac"; }
.o-exclamation-mark-1::before { content: "\faad"; }
.o-exit-1::before { content: "\faae"; }
.o-expand-1::before { content: "\faaf"; }
.o-export-1::before { content: "\fab0"; }
.o-external-link-1::before { content: "\fab1"; }
.o-files-1::before { content: "\fab2"; }
.o-filter-data-1::before { content: "\fab3"; }
.o-flag-1::before { content: "\fab4"; }
.o-hidden-1::before { content: "\fab5"; }
.o-id-card-1::before { content: "\fab6"; }
.o-import-1::before { content: "\fab7"; }
.o-important-warning-1::before { content: "\fab8"; }
.o-info-1::before { content: "\fab9"; }
.o-info-help-1::before { content: "\faba"; }
.o-key-1::before { content: "\fabb"; }
.o-lock-1::before { content: "\fabc"; }
.o-lock-opened-1::before { content: "\fabd"; }
.o-mail-1::before { content: "\fabe"; }
.o-map-1::before { content: "\fabf"; }
.o-maximize-1::before { content: "\fac0"; }
.o-menu-1::before { content: "\fac1"; }
.o-minus-1::before { content: "\fac2"; }
.o-moon-waning-1::before { content: "\fac3"; }
.o-more-1::before { content: "\fac4"; }
.o-new-message-1::before { content: "\fac5"; }
.o-numbers-1::before { content: "\fac6"; }
.o-pie-chart-1::before { content: "\fac7"; }
.o-pin-1::before { content: "\fac8"; }
.o-play-movie-1::before { content: "\fac9"; }
.o-priority-warning-1::before { content: "\faca"; }
.o-question-1::before { content: "\facb"; }
.o-question-help-1::before { content: "\facc"; }
.o-reception-1::before { content: "\facd"; }
.o-reload-1::before { content: "\face"; }
.o-repeat-1::before { content: "\facf"; }
.o-rfid-signal-1::before { content: "\fad0"; }
.o-rotate-right-1::before { content: "\fad1"; }
.o-sales-down-1::before { content: "\fad2"; }
.o-sales-performance-down-1::before { content: "\fad3"; }
.o-sales-performance-up-1::before { content: "\fad4"; }
.o-sales-up-1::before { content: "\fad5"; }
.o-sand-clock-1::before { content: "\fad6"; }
.o-search-1::before { content: "\fad7"; }
.o-shield-1::before { content: "\fad8"; }
.o-sliders-1::before { content: "\fad9"; }
.o-smartphone-1::before { content: "\fada"; }
.o-sorting-1::before { content: "\fadb"; }
.o-speaker-call-1::before { content: "\fadc"; }
.o-statistic-1::before { content: "\fadd"; }
.o-statistics-1::before { content: "\fade"; }
.o-suitcase-1::before { content: "\fadf"; }
.o-sunny-1::before { content: "\fae0"; }
.o-switch-camera-1::before { content: "\fae1"; }
.o-team-1::before { content: "\fae2"; }
.o-time-1::before { content: "\fae3"; }
.o-type-1::before { content: "\fae4"; }
.o-upload-1::before { content: "\fae5"; }
.o-upload-to-cloud-1::before { content: "\fae6"; }
.o-user-1::before { content: "\fae7"; }
.o-view-1::before { content: "\fae8"; }
.o-web-link-1::before { content: "\fae9"; }
.o-zoom-in-1::before { content: "\faea"; }
.o-zoom-out-1::before { content: "\faeb"; }

  