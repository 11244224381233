@font-face {
    src: url(Inter-Black.ttf);
    font-family: Inter;
    font-weight: 900;
}

@font-face {
    src: url(Inter-ExtraBold.ttf);
    font-family: Inter;
    font-weight: 800;
}

@font-face {
    src: url(Inter-Bold.ttf);
    font-family: Inter;
    font-weight: bold;
}

@font-face {
    src: url(Inter-SemiBold.ttf);
    font-family: Inter;
    font-weight: 600;
}

@font-face {
    src: url(Inter-Medium.ttf);
    font-family: Inter;
    font-weight: 500;
}

@font-face {
    src: url(Inter-Regular.ttf);
    font-family: Inter;
    font-weight: normal;
}

@font-face {
    src: url(Inter-Light.ttf);
    font-family: Inter;
    font-weight: 300;
}

@font-face {
    src: url(Inter-ExtraLight.ttf);
    font-family: Inter;
    font-weight: 200;
}

@font-face {
    src: url(Inter-Thin.ttf);
    font-family: Inter;
    font-weight: 100;
}