@import "assets/fonts/fonts.css";
@import "assets/icons/iconfont.css";

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-input-prefix {
  width: 20px;
}

.ant-modal-mask {
  backdrop-filter: blur(6px);
}

.setup-modal .ant-modal-body {
  padding: 40px !important
}

.ant-tooltip-inner {
  background-color: #272935 !important;
  padding: 10px 20px;
}

.ant-tooltip-arrow-content {
  background-color: #272935 !important;
}

.setup-modal .ant-modal-confirm-title {}

.ant-result-subtitle {
  color: #B2B6C7;
  font-size: 16px;
}
